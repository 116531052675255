<template>
  <div class="orderDetailPage">
    <topabr :appJoin="appJoin" bgColor="#F2F3F5" category="reorder"></topabr>
    <div class="title">
      <span>{{ orderInfo.orderStatus }}</span>
      <span
        class="codeText"
        v-if="orderInfo.orderType != 1 && orderInfo.orderType != 101"
      >
        <span>{{ quCanHaoMaT }}</span
        >{{ orderInfo.shopBillNo }}</span
      >
    </div>
    <!-- <div
      class="codeNum"
      v-if="orderInfo.orderType != 1 && orderInfo.orderType != 101"
    >
      <span>取餐</span>
      <span>{{ quCanHaoMa }}</span>
    </div> -->
    <div class="orderAgain" v-if="orderInfo.eleIndex == 4" @click="changePage">
      {{ zaiCiXiaDan }}
    </div>
    <div class="progressBox">
      <div class="progress" v-if="orderInfo.orderStatusCode != 'S008'">
        <div
          :class="{
            active: orderInfo.eleIndex == 1,
          }"
        >
          <i class="icon1"></i>
          <p>{{ queRenDingdan }}</p>
        </div>
        <span></span>
        <div
          :class="{
            active: orderInfo.eleIndex == 2,
            bgActive: orderInfo.eleIndex > 2,
          }"
        >
          <i class="icon2" :class="{ active: orderInfo.eleIndex < 2 }"></i>
          <p>{{ zhunBeiZhong }}</p>
        </div>
        <span></span>
        <div
          :class="{
            active: orderInfo.eleIndex == 3,
            bgActive: orderInfo.eleIndex > 3,
          }"
          v-if="orderInfo.orderType == 1 || orderInfo.orderType == 101"
        >
          <i class="icon5"></i>
          <p>{{ songDaZhong }}</p>
        </div>
        <div
          :class="{
            active: orderInfo.eleIndex == 3,
            bgActive: orderInfo.eleIndex > 3,
          }"
          v-else
        >
          <i class="icon3"></i>
          <p>{{ zhunBeiFinsih }}</p>
        </div>

        <span></span>
        <div
          :class="{
            active: orderInfo.eleIndex == 4,
          }"
        >
          <i class="icon4"></i>
          <p>{{ yiWanCheng }}</p>
        </div>
      </div>
      <i class="line"></i>
      <div
        class="food"
        v-for="item in orderInfoOne.orderProducts"
        :key="item.name"
      >
        <img src="@/assets/icon/orderIcon.png" alt="" />
        <div class="foodInfo">
          <p class="foodName">
            <span>{{
              $store.state.language == 1 ? item.name : item.nameEn
            }}</span>
            <span>x {{ item.num }}</span>
          </p>
          <p
            class="foodName active"
            v-for="spro in item.products"
            :key="spro.pid"
          >
            <span>{{
              $store.state.language == 1 ? spro.name : spro.nameEn
            }}</span>
            <span>x {{ spro.num }}</span>
          </p>
          <div class="foodNum">
            <p></p>
            <p>
              $<span>{{ item.realTimeTotalPrice }}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="pre"
        v-if="orderInfoOne.deliveryShow && (orderInfoOne.sendType == 1 || orderInfoOne.sendType == 4)"
      >
        <p v-if="$store.state.language == 1">{{ new_QRDD_peiSongFei }}</p>
        <p v-else>{{ new_QRDD_peiSongFei }}</p>
        <p>$ {{ orderInfoOne.deliveryFee }}</p>
      </div>
      <div v-for="(pre, index) in orderInfoOne.orderPreferentials" :key="index">
        <div class="pre" v-if="pre.type != 7">
          <p v-if="$store.state.language == 1">
            {{ pre.type == 5 ? new_QRDD_peiSongFei : pre.content }}
          </p>
          <p v-else>{{ pre.type == 5 ? new_QRDD_peiSongFei : pre.content }}</p>
          <p >$ {{ pre.price }}</p>
        </div>
        <div
          v-if="
            orderInfo.vouchers &&
            orderInfo.vouchers.length &&
            (orderInfo.vouchers[0].voucherType == 1 ||
              orderInfo.vouchers[0].voucherType == 2 ||
              orderInfo.vouchers[0].voucherType == 4)
          "
        >
          <div class="pre" v-if="pre.type == 7 && pre.childType == 3">
            <p v-if="$store.state.language == 1">
              {{ pre.content }}
            </p>
            <p v-else>{{ pre.contentEng }}</p>
            <p>$ {{ pre.price }}</p>
          </div>
        </div>
      </div>

      <i class="line"></i>
      <p class="realPay">
        {{ zongJiJinE }}: $<span>
          {{ parseInt(orderInfo.amount) / 100 || 0 }}</span
        >
      </p>
    </div>
    <div class="orderInfo">
      <p class="orderInfoCell">
        <span>{{ canJu }}</span>
        <span>{{ canjuText }}</span>
      </p>
      <!-- <p
        class="orderInfoCell CA"
        v-if="orderInfo.orderType == 1 || orderInfo.orderType == 101"
      > -->
      <p class="orderInfoCell CA" v-if="false">
        <span>{{ JJJY }}</span>
        <span>{{ orderInfo.isAddressIsolation ? YES : NO }}</span>
      </p>
      <!-- <p
        class="orderInfoCell CA"
        v-if="orderInfo.orderType == 1 || orderInfo.orderType == 101"
      > -->
      <p class="orderInfoCell CA" v-if="false">
        <span>{{ WJCFW }}</span>
        <span>{{ orderInfo.isolatedPerson ? YES : NO }}</span>
      </p>
    </div>
    <div class="orderInfo">
      <p class="orderInfoTitle">{{ dingDanZiLiao }}</p>
      <p
        class="orderInfoCell"
        v-if="orderInfoOne.sendType == 1 || orderInfoOne.sendType == 101"
      >
        <span>{{ new_DDXQ_songDaShiJian }}</span>
        <span>{{ orderInfo.quoDateTime }}</span>
      </p>
      <p class="orderInfoCell" v-else-if="orderInfoOne.sendType != 3">
        <span>{{ DDXQ_qucan }}</span>
        <span v-show="orderInfo.quoDateTime">
          {{ $store.state.language == 1 ? "預計" : "Estimated Ready Time at " }}
          {{ orderInfo.quoDateTime }}
          {{ $store.state.language == 1 ? "準備好" : "" }}
        </span>
      </p>
      <p
        class="orderInfoCell"
        v-if="orderInfo.orderType != 1 && orderInfo.orderType != 101"
      >
        <span>{{ QC_mendian }}</span>
        <span>
          {{ orderInfo.shopName }}
        </span>
      </p>
      <div
        class="orderInfoCell sendPlace"
        v-if="orderInfo.orderType == 1 || orderInfo.orderType == 101"
      >
        <p class="addressTitle">{{ new_DDXQ_songCanDiZhi }}</p>
        <div>
          <p>{{ orderInfoOne.address || "" }}</p>
          <p>
            {{ orderInfoOne.name || "" }}先生 +852
            {{ orderInfoOne.phone || "" }}
          </p>
        </div>
      </div>
      <p
        class="orderInfoCell"
        v-if="orderInfo.orderType != 1 && orderInfo.orderType != 101"
      >
        <span>{{ quCanHaoMaT }}</span>
        <span>{{ orderInfo.shopBillNo }}</span>
      </p>
      <p class="orderInfoCell">
        <span>{{ xiaDanShiJian }}</span>
        <span>{{ orderInfoOne.orderDate }}</span>
      </p>
      <p class="orderInfoCell">
        <span>{{ dingDanHaoMa }}</span>
        <span>{{ orderInfo.transactionId }}</span>
      </p>
      <p class="orderInfoCell">
        <span>{{ Payment }}</span>
        <span>{{ orderInfo.billType }}</span>
      </p>
    </div>
    <p class="custerPhone">{{ FWRX_num }} 21800000</p>
    <confirm-box
      v-show="showConfirm"
      @finish="finish"
      :finishBtn="finishBtn"
      :showBill="showBill"
      :shopBillNo="orderInfo.shopBillNo"
      :title="title"
      :content="content"
    ></confirm-box>
  </div>
</template>
<script>
import Topabr from "../../components/Topbar.vue";
import ConfirmBox from "../../components/common/CnfirmBox.vue";
import { postDC, postPaas } from "../../assets/utils/request";
import language from "../../assets/js/language";
export default {
  name: "OrderDetail",
  data() {
    return {
      eleIndex: 0,
      eatType: 1, // 1外送  2堂食
      orderStatus: "準備緊", //订单状态
      recordId: null,
      orderInfo: {}, //订单信息
      orderInfoOne: {}, //订单信息
      title: "", //弹窗标题
      content: "", //弹窗内容
      finishBtn: "",
      showConfirm: false,
      showBill: true,
      canjuText: "",
      lanType: this.$store.state.language,
      pageParam: null,
      appJoin: false,
      // 靜態文字
      queRenDingdan: "",
      zhunBeiZhong: "",
      zhunBeiFinsih: "",
      yiWanCheng: "",
      dingDanZiLiao: "",
      zaiCiXiaDan: "",
      dingDanHaoMa: "",
      xiaDanShiJian: "",
      canJu: "",
      quCanHaoMa: "",
      quCanHaoMaT: "",
      zongJiJinE: "",
      songDaZhong: "",
      Payment: "",
      dingDanQuXiao: "",
      new_DDXQ_songDaShiJian: "",
      new_DDXQ_songCanDiZhi: "",
      new_QRDD_peiSongFei: "",
      DDXQ_qucan: "",
      FWRX_num: "",
      QC_mendian: "",
      JJJY: "",
      NO: "",
      YES: "",
      HQXXSB: "",
    };
  },
  methods: {
    async getOrderDetial() {
      var params = {
        actionName: "candao.member.orderDetail",
        content: {
          recordId: this.recordId,
        },
      };
      if (this.pageParam.deviceId) {
        params.content.deviceId = this.pageParam.deviceId;
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        if (
          result.data.orderStatusCode == "S002" ||
          result.data.orderStatusCode == "S003"
        ) {
          result.data.orderStatus = this.queRenDingdan;
        } else if (result.data.orderStatusCode == "S004") {
          result.data.orderStatus = this.zhunBeiZhong;
        } else if (
          result.data.orderStatusCode == "S005" ||
          result.data.orderStatusCode == "S009"
        ) {
          if (result.data.orderType == 1 || result.data.orderType == 101) {
            result.data.orderStatus = this.songDaZhong;
          } else {
            result.data.orderStatus = this.zhunBeiFinsih;
          }
        } else if (
          result.data.orderStatusCode == "S006" ||
          result.data.orderStatusCode == "S007" ||
          result.data.orderStatusCode == "S010"
        ) {
          result.data.orderStatus = this.yiWanCheng;
        } else if (result.data.orderStatusCode == "S008") {
          result.data.orderStatus = this.dingDanQuXiao;
        }
        if (result.data.quoDateTime) {
          var time = result.data.quoDateTime.split("T");
          var D = time[0].split("-");
          var T = time[1].split(":");
          var NTY = new Date().getFullYear();
          var NTM = new Date().getMonth() + 1;
          var NTD = new Date().getDate();
          var NT = new Date(NTY + "/" + NTM + "/" + NTD).getTime();
          var YT = new Date(D[0] + "/" + D[1] + "/" + D[2]).getTime();
          if (YT != NT) {
            result.data.quoDateTime =
              D[1] + "/" + D[2] + " " + T[0] + ":" + T[1];
          } else {
            result.data.quoDateTime = T[0] + ":" + T[1];
          }
          // result.data.orderType == 1
          //   ?
          //   : T[0] + ":" + T[1];
        }
        this.orderInfo = result.data;
        if (result.data.orderType == 1 || result.data.orderType == 101) {
          this.showBill = false;
        }
        var length = result.data.items.length;
        if (result.data.items[length - 1].productCode == "XA990") {
          if (this.$store.state.language == 1) {
            this.canjuText = "不需要";
          } else {
            this.canjuText = "No";
          }
        } else {
          if (this.$store.state.language == 1) {
            this.canjuText = "需要";
          } else {
            this.canjuText = "Yes";
          }
        }
        this.getDetail(this.orderInfo.reference);
      }
    },
    async getDetail(id) {
      var params = {
        actionName: "candao.orderOwn.getOrderDetail",
        content: {
          orderId: id,
        },
      };
      if (this.pageParam.deviceId) {
        params.content.deviceId = this.pageParam.deviceId;
      }
      let result = await postDC("Action", params);
      if (result.status == 1) {
        if (result.data.sendTime) {
          var time = result.data.sendTime.split(" ");
          var D = time[0].split("-");
          var T = time[1].split(":");
          result.data.sendTime =
            result.data.sendType == 1
              ? D[1] + "/" + D[2] + " " + T[0] + ":" + T[1]
              : T[0] + ":" + T[1];
        }
        if (result.data.orderDate) {
          var Xtime = result.data.orderDate.split(" ");
          var XD = Xtime[0].split("-");
          var XT = Xtime[1].split(":");
          result.data.orderDate =
            XD[1] + "/" + XD[2] + " " + XT[0] + ":" + XT[1];
        }
        this.orderInfoOne = result.data;
        var clickMap = localStorage.getItem("orderMap");
        var oIndex = -1;
        // if (
        //   this.orderInfoOne.orderProducts &&
        //   this.orderInfoOne.orderProducts.length
        // ) {
        //   for (var o = 0; o < this.orderInfoOne.orderProducts.length; o++) {
        //     let orderProducts = this.orderInfoOne.orderProducts;
        //     console.log(orderProducts);
        //   }
        // }
        for (var i = 0; i < this.orderInfoOne.orderPreferentials.length; i++) {
          if (
            this.orderInfoOne.orderPreferentials[i].type == 2 &&
            this.orderInfoOne.orderPreferentials[i].childType == 4 &&
            this.orderInfoOne.sendType == 2
          ) {
            this.orderInfoOne.orderPreferentials[i].content =
              "85折 (只限門店自取)";
            this.orderInfoOne.orderPreferentials[i].contentEng =
              "15% off (Self Pick-up Only)";
          }
          this.orderInfoOne.orderPreferentials[i].content =
            this.orderInfoOne.orderPreferentials[i].content.replaceAll(
              "¥",
              "$"
            );
          this.orderInfoOne.orderPreferentials[i].content =
            this.orderInfoOne.orderPreferentials[i].content.replaceAll(
              "￥",
              "$"
            );
          this.orderInfoOne.orderPreferentials[i].contentEng =
            this.orderInfoOne.orderPreferentials[i].contentEng.replaceAll(
              "¥",
              "$"
            );
          this.orderInfoOne.orderPreferentials[i].contentEng =
            this.orderInfoOne.orderPreferentials[i].contentEng.replaceAll(
              "￥",
              "$"
            );
          this.orderInfoOne.orderPreferentials[i].content =
            this.orderInfoOne.orderPreferentials[i].content.replaceAll(
              "HKD",
              "$"
            );
          this.orderInfoOne.orderPreferentials[i].contentEng =
            this.orderInfoOne.orderPreferentials[i].contentEng.replaceAll(
              "HKD",
              "$"
            );
          if (this.orderInfoOne.orderPreferentials[i].type == 5) {
            this.orderInfoOne.orderPreferentials[i].price =
              this.orderInfoOne.deliveryFee +
              this.orderInfoOne.orderPreferentials[i].price;
            oIndex = i;
          }
        }
        if (oIndex == -1) {
          this.orderInfoOne.deliveryShow = true;
        }

        if (!clickMap) {
          this.showConfirm = true;
          var clcikIds = [];
          clcikIds.push(id);
          localStorage.setItem("orderMap", JSON.stringify(clcikIds));
        } else {
          clickMap = JSON.parse(clickMap);
          var index = clickMap.indexOf(id);
          if (index < 0) {
            this.showConfirm = true;
            clickMap.push(id);
            localStorage.setItem("orderMap", JSON.stringify(clickMap));
          }
        }
        // this.dataLayerPush();
      } else {
        this.$toast(this.HQXXSB);
      }

      this.$nextTick(() => {
        var progressEle = document.querySelectorAll(".progress div");
        if (
          this.orderInfo.orderStatusCode == "S002" ||
          this.orderInfo.orderStatusCode == "S003"
        ) {
          this.eleIndex = 1;
        } else if (this.orderInfo.orderStatusCode == "S004") {
          this.eleIndex = 2;
        } else if (
          this.orderInfo.orderStatusCode === "S005" ||
          this.orderInfo.orderStatusCode === "S009"
        ) {
          this.eleIndex = 3;
        } else if (
          this.orderInfo.orderStatusCode == "S007" ||
          this.orderInfo.orderStatusCode == "S006" ||
          this.orderInfo.orderStatusCode == "S010"
        ) {
          this.eleIndex = 4;
        }
        this.orderInfo.eleIndex = this.eleIndex;
        for (this.eleIndex; this.eleIndex < 5; this.eleIndex++) {
          if (progressEle[this.eleIndex]) {
            progressEle[this.eleIndex].style.color = "#b1b1b3";
          }
        }
      });
    },
    finish() {
      this.showConfirm = false;
    },
    // 再来一单
    async changePage() {
      if (!this.orderInfoOne && !this.orderInfoOne.storeId)
        return this.$toast(this.HQXXSB);
      let menuParam = {
        actionName: "candao.product.getProductMenu",
        content: {
          storeId: this.orderInfoOne.storeId,
          sendType: this.orderInfoOne.sendType,
        },
      };
      if (this.pageParam.deviceId) {
        menuParam.content.deviceId = this.pageParam.deviceId;
      }
      let menuResult = await postDC("Action", menuParam);
      if (menuResult.status != 1) return this.$toast(this.HQXXSB);
      var againParams = {
        actionName: "candao.orderOwn.reApplyOrder",
        content: {
          orderId: this.orderInfoOne.orderId,
          menuId: menuResult.data.menuId,
        },
      };
      if (this.pageParam.deviceId) {
        againParams.content.deviceId = this.pageParam.deviceId;
      }

      let againResult = await postDC("Action", againParams);
      if (againResult.status == 1) {
        if (againResult.data.productsOwn) {
          var productsOwn = againResult.data.productsOwn;
          var product = [];
          for (var i = 0; i < productsOwn.length; i++) {
            var obj = {};
            for (var key in productsOwn[i]) {
              if (typeof productsOwn[i][key] != "object") {
                obj[key] = productsOwn[i][key];
              }
            }
            obj.selectedFood = productsOwn[i];
            product.push(obj);
          }
        }

        var foodParam = {
          storeId: this.orderInfoOne.storeId,
          sendType: this.orderInfoOne.sendType,
          products: product || [],
        };

        if (this.pageParam.app) {
          foodParam = this.pageParam;
          foodParam.reference = this.orderInfo.reference;
        }
        if (againResult.data.errorProducts) {
          foodParam.errorFood = againResult.data.msg;
        }
        window.dataLayer.push({
          event: "reorder",
        });
        foodParam = JSON.stringify(foodParam);
        foodParam = encodeURIComponent(foodParam);
        this.$router.push("/orderFood/" + foodParam);
      }
    },
    dataLayerPush() {
      var items = [];
      var products = this.orderInfoOne.orderProducts;
      for (var p = 0; p < products.length; p++) {
        var itemObj = {};
        itemObj.item_list_name = "";
        itemObj.item_list_id = "";
        itemObj.quantity = products[p].num;
        itemObj.item_id = products[p].pid;
        itemObj.item_variant = products[p].name;
        itemObj.item_brand = "";
        items.push(itemObj);
      }

      var ecommerce = {
        id: this.orderInfo.transactionId,
        store: this.orderInfo.shopName,
        service:
          this.orderInfo.orderType == 1
            ? "delivery"
            : this.pageParam.orderType == 2
            ? "Takeaway"
            : "dine in",
        payment: this.orderInfo.billType,
        preorder: "",
        cutlery:
          this.orderInfo.items[this.orderInfo.items.length - 1].name ==
          "D-ENVIRONMENTAL"
            ? false
            : true,
        value: this.orderInfo.totalPrice,
        currency: "$",
        safe_delivery: "",
        address_variant: this.orderInfoOne.address,
        items,
      };
      if (this.$store.state.userInfo) {
        ecommerce.membership_id = this.$store.state.userInfo.userId;
        ecommerce.yuu_id = this.$store.state.userInfo.yuuId || "";
      }
      if (
        this.orderInfoOne.orderPreferentials &&
        this.orderInfoOne.orderPreferentials.length
      ) {
        var orderPreferentials = this.orderInfoOne.orderPreferentials;
        for (var i = 0; i < orderPreferentials.length; i++) {
          if (
            orderPreferentials[i].type == 7 &&
            orderPreferentials[i].childType == 7
          ) {
            ecommerce.coupon = orderPreferentials[i].content;
          } else if (
            orderPreferentials[i].type == 2 &&
            orderPreferentials[i].childType == 3
          ) {
            ecommerce.promotion = orderPreferentials[i].content;
          }
        }
      }
      if (this.$store.state.app) {
        ecommerce.affiliation = "app";
      } else {
        ecommerce.affiliation = "mobile";
      }
      window.dataLayer.push({
        event: "purchase",
        ecommerce,
      });
    },
    setLanguage() {
      var type = "chinese";
      if (this.$store.state.language != 1) {
        type = "english";
      }
      this.queRenDingdan = language.queRenDingdan[type];
      this.zhunBeiZhong = language.zhunBeiZhong[type];
      this.zhunBeiFinsih = language.zhunBeiFinsih[type];
      this.yiWanCheng = language.yiWanCheng[type];
      this.dingDanQuXiao = language.dingDanQuXiao[type];
      this.dingDanZiLiao = language.dingDanZiLiao[type];
      this.zaiCiXiaDan = language.zaiCiXiaDan[type];
      this.dingDanHaoMa = language.dingDanHaoMa[type];
      this.xiaDanShiJian = language.xiaDanShiJian[type];
      this.canJu = language.canJu[type];
      this.quCanHaoMa = language.quCanHaoMa[type];
      this.quCanHaoMaT = language.quCanHaoMaT[type];

      this.zongJiJinE = language.zongJiJinE[type];
      this.songDaZhong = language.songDaZhong[type];
      this.Payment = language.new_DDXQ_zffs[type];
      this.title = language.shouDaoDingDan[type];
      this.content = language.dingDanZhuangTai[type];
      this.finishBtn = language.new_DDXQ_fanHui[type];
      this.new_DDXQ_songDaShiJian = language.new_DDXQ_songDaShiJian[type];
      this.new_DDXQ_songCanDiZhi = language.new_DDXQ_songCanDiZhi[type];
      this.new_QRDD_peiSongFei = language.new_QRDD_peiSongFei[type];
      this.DDXQ_qucan = language.DDXQ_qucan[type];
      this.FWRX_num = language.FWRX_num[type];
      this.QC_mendian = language.QC_mendian[type];
      this.JJJY = language.JJJY[type];
      this.WJCFW = language.WJCFW[type];
      this.NO = language.NO[type];
      this.YES = language.YES[type];
      this.HQXXSB = language.HQXXSB[type];
    },
    getParam() {
      this.$nextTick(() => {
        localStorage.removeItem("KFCYK");
        setTimeout(() => {
          this.pageParam = this.$route.params.info;
          var isJSON = false;
          try {
            this.pageParam = JSON.parse(this.pageParam);
            isJSON = true;
          } catch {
            isJSON = false;
          }
          var KFCClear = localStorage.getItem("KFCClear");
          if (KFCClear) {
            localStorage.removeItem("KFCClear");
            this.$store.commit("clearCart");
          }
          if (isJSON && this.pageParam.app) {
            this.appJoin = true;
            if (this.pageParam.deviceId) {
              this.$store.commit("saveDeviceId", this.pageParam.deviceId);
            } else {
              this.$store.commit("saveDeviceId", null);
            }
            this.$store.commit("saveApp", this.pageParam.app);
            this.appInfo = this.pageParam;
            this.recordId = this.pageParam.recordId;
            this.getPersionInfo();
            if (this.pageParam.positionInfo) {
              var copyInfo = {
                building: this.pageParam.positionInfo.address,
                district: this.pageParam.positionInfo.address,
                lat: this.pageParam.positionInfo.latitude,
                lng: this.pageParam.positionInfo.longitude,
              };
              if (this.pageParam.positionInfo.name) {
                copyInfo.contactPerson = this.pageParam.positionInfo.name;
              }
              if (this.pageParam.positionInfo.phone) {
                copyInfo.phoneNo = this.pageParam.positionInfo.phone;
              }
              this.$store.commit("savePlace", copyInfo);
            }
            this.$store.commit("savePosition", this.pageParam.positionInfo);
            if (this.pageParam.language) {
              if (this.pageParam.language == 0) {
                this.pageParam.language = 1;
              }
              this.$store.commit("setLanguage", this.pageParam.language);
            }
          } else {
            this.$store.commit("saveApp", false);
            this.recordId = this.pageParam;
            this.getOrderDetial();
          }
          this.setLanguage();
        }, 500);
      });
    },
    // 获取用户详情
    async getPersionInfo() {
      let paramsOne = {
        actionName: "candao.user.getUserByExtUserId",
        content: {
          extUserId: this.pageParam.userId,
          phone: this.pageParam.phone,
        },
      };
      if (this.pageParam.deviceId) {
        paramsOne.content.deviceId = this.pageParam.deviceId;
      }
      // 获取secretKey
      let canDaoUser = await postDC("Action", paramsOne);
      if (canDaoUser.status != 1) {
        this.$toast(this.HQXXSB);
        return;
      }
      var paramsTwo = {
        actionName: "candao.member.bindAccessCode",
        content: {
          accessCode: this.pageParam.accecode,
        },
      };
      if (this.pageParam.deviceId) {
        paramsTwo.content.deviceId = this.pageParam.deviceId;
      }
      let bindCode = await postPaas("UserUnify", paramsTwo);
      if (bindCode.status != 1) {
        this.$toast(this.HQXXSB);
        return;
      }
      var params = {
        actionName: "candao.member.userProfileGet",
        content: {},
      };
      if (this.pageParam.deviceId) {
        params.content.deviceId = this.pageParam.deviceId;
      }
      // if (this.$store.state.userInfo) {
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.$store.commit("setUserInfo", result.data);
        this.getOrderDetial();
      } else {
        this.$toast(this.HQXXSB);
      }
      // }
    },
  },
  created() {
    this.getParam();
    // var params = this.$route.params;
    // this.recordId = params.info;
    // this.getOrderDetial();
  },
  components: {
    Topabr,
    ConfirmBox,
  },
};
</script>
<style scoped>
.orderDetailPage {
  background-color: #f2f3f5;
  padding-bottom: 1rem;
}
.title {
  height: 0.33rem;
  font-size: 0.24rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.33rem;
  color: #1f2123;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.16rem;
  box-sizing: border-box;
  margin-top: 0.1rem;
}
.title span {
  line-height: 0.33rem;
}
.title span:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.title span:nth-child(2),
.codeNum span:nth-child(2) {
  color: #e4022b;
}
.codeNum {
  display: flex;
  justify-content: space-between;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #808080;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.codeNum span:first-child {
  width: 2.9rem;
  text-align: left;
  font-size: 0.11rem !important;
}
.progressBox,
.orderInfo,
.orderAgain {
  margin: 0.16rem 0.16rem 0.12rem;
  background-color: #fff;
  border-radius: 0.12rem;
  padding: 0.16rem;
  box-sizing: border-box;
}

.orderAgain {
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.2);
}
.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
  color: #000;
  margin-bottom: 0.25rem;
}

.progress div.active {
  color: #e4022b;
}

.progress div i {
  width: 0.32rem;
  height: 0.32rem;
}
.progress div p {
  white-space: nowrap;
}
.progress span {
  width: 0.42rem;
  height: 0.01rem;
  background: url("../../assets/icon/linez.png") no-repeat center;
  background-size: 0.42rem 0.01rem;
  margin-top: -0.1rem;
}
.progress div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon1 {
  background: url("../../assets/icon/send1a.png") no-repeat center;
  background-size: cover;
}
.progress div.active .icon1 {
  background: url("../../assets/icon/icon1aActive.png") no-repeat center;
  background-size: cover;
}
.icon2 {
  background: url("../../assets/icon/send2.png") no-repeat center;
  background-size: cover;
}
.icon2.active {
  opacity: 0.4;
}
.progress div.active .icon2 {
  background: url("../../assets/icon/send2a.png") no-repeat center;
  background-size: cover;
}
.progress div.bgActive .icon2 {
  background: url("../../assets/icon/send2.png") no-repeat center;
  background-size: cover;
}

.icon3 {
  background: url("../../assets/icon/send3.png") no-repeat center;
  background-size: cover;
}
.progress div.active .icon3 {
  background: url("../../assets/icon/send3a.png") no-repeat center;
  background-size: cover;
}
.progress div.bgActive .icon3 {
  background: url("../../assets/icon/send3c.png") no-repeat center;
  background-size: cover;
}
.icon4 {
  background: url("../../assets/icon/send4.png") no-repeat center;
  background-size: cover;
}
.progress div.active .icon4 {
  background: url("../../assets/icon/send4a.png") no-repeat center;
  background-size: cover;
}
.icon5 {
  background: url("../../assets/icon/send33.png") no-repeat center;
  background-size: cover;
}
.progress div.active .icon5 {
  background: url("../../assets/icon/send33a.png") no-repeat center;
  background-size: cover;
}
.progress div.bgActive .icon5 {
  background: url("../../assets/icon/send33c.png") no-repeat center;
  background-size: cover;
}
.line {
  width: 3.1rem;
  height: 0.01rem;
  display: block;
  background-color: #cbcbcc;
}
.food {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.12rem;
  margin-bottom: 0.16rem;
}
.food img {
  width: 0.46rem;
  height: 0.46rem;
  object-fit: cover;
  margin-right: 0.08rem;
}
.foodName {
  width: 100%;
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 18px.2rem;
  color: #030f14;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.03rem;
}
.foodName span:first-child {
  width: 2.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.foodName.active {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.1rem;
  line-height: 0.14rem;
  color: #787878;
}
.foodContent {
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #b1b1b3;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.foodNum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.22rem;
  color: #131314;
  border-top: 0.01rem dashed #cbcbcc;
  margin-top: 0.06rem;
  padding-top: 0.05rem;
}
.foodNum p:nth-child(2) {
  font-size: 0.1rem;
}
.foodNum p span {
  font-size: 0.16rem;
}
.realPay {
  width: 100%;
  text-align: right;
  height: 0.22rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #03060d;
  margin-top: 0.16rem;
}
.realPay span {
  font-size: 0.16rem;
}

.orderInfo {
  padding-top: 0;
  padding-bottom: 0;
}
.orderInfoTitle {
  height: 0.54rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.54rem;
  color: #03060d;
  text-align: left;
  border-bottom: 0.01rem solid #cacbcc;
}
.orderInfoCell {
  height: 0.54rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #474747;
  border-bottom: 0.01rem dashed #cacbcc;
  text-align: justify;
}
.orderInfoCell.CA span:first-child {
  width: 80%;
  text-align: justify;
  word-break: break-all;
}
.orderInfo .orderInfoCell:last-child {
  border: none;
}
.sendPlace {
  align-items: flex-start;
  height: auto;
  padding: 0.16rem 0;
}
.sendPlace div p {
  text-align: right;
  word-break: break-all;
  word-wrap: break-word;
}
.sendPlace .addressTitle {
  white-space: nowrap;
}
.sendPlace div p + p {
  margin-top: 0.04rem;
}
.orderAgain {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #e4022b;
}
.foodInfo {
  width: 100%;
}
.pre {
  width: 100%;
  height: 0.2rem;
  font-size: 0.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.16rem;
}
.custerPhone {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.12rem;
  color: #808080;
  text-align: left;
  padding: 0 0.16rem 0.13rem;
  box-sizing: border-box;
  margin-top: 0.28rem;
}
.codeText {
  font-size: 0.32rem;
}
.codeText span:first-child {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  color: #e4022b;
  font-size: 0.11rem;
  margin-right: 0.05rem;
}
</style>